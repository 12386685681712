import React from 'react'
import { Link } from 'gatsby'

const CallToAction = ({ work }) => {
  let text = <h2>Let’s get talking!</h2>
  if (!work) {
    text = (
      <h2>
        See all our <Link to="/work">work</Link>
        <br /> or let’s get talking!
      </h2>
    )
  }
  return (
    <div className="cta">
      {text}
      <Link className="button" to="/about">
        <div>Get in touch</div>
      </Link>
    </div>
  )
}

export default CallToAction
