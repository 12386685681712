import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/Meta'
import VimeoPlayer from 'react-player/lib/players/Vimeo'
import Layout from '../components/Layout'
import Calltoaction from '../components/CTA'
import './project.scss'

function Slice({ type, primary, items }) {
  if (type === `tekst`) {
    return (
      <div
        className="slice text"
        dangerouslySetInnerHTML={{ __html: primary.text.html }}
      />
    )
  } else if (type === `vimeo`) {
    return <VimeoPlayer className="slice vimeo" url={primary.vimeo_url} />
  } else if (type === `afbeelding`) {
    return (
      <figure
        className={
          primary.alignment === `Links`
            ? `align-left slice`
            : primary.alignment === `Rechts`
            ? `align-right slice`
            : `slice`
        }
      >
        <img
          alt={primary.alt_text}
          src={primary.image.thumbnails.small.url}
          srcSet={`
            ${primary.image.thumbnails.small.url} 500w,
            ${primary.image.thumbnails.medium.url} 1000w,
            ${primary.image.thumbnails.large.url} 1600w,
            ${primary.image.url} 2800w
            `}
          sizes={
            primary.alignment === (`Links` | `Rechts`)
              ? `
          (max-width: 500px) calc(100vw - 36px),
          (max-width: 1000px) calc(((100vw - 144px) / 3) * 2),
          (max-width: 1688px) calc(((100vw - 288px) / 4) * 3),
          930px
          `
              : `
          (max-width: 500px) calc(100vw - 36px),
          (max-width: 1000px) calc(100vw - 144px),
          (max-width: 1688px) calc(100vw - 288px),
          1400px
          `
          }
        />
        {primary.caption ? <figcaption>{primary.caption}</figcaption> : null}
      </figure>
    )
  } else if (type === `afbeeldingengrid`) {
    return (
      <ImageGrid
        size={primary.size.toLowerCase()}
        caption={primary.caption}
        items={items}
      />
    )
  } else {
    return null
  }
}

const ImageGrid = ({ size, caption, items }) => {
  const getSizes = size => {
    if (size === `small`) {
      return `
      (max-width: 500px) calc((100vw - 36px - 18px) / 2),
      (max-width: 666px) calc(((100vw - 144px - 18px) / 2)),
      (max-width: 845px) calc(((100vw - 144px - 36px) / 3)),
      (max-width: 1000px) calc(((100vw - 144px - 54px) / 4)),
      (max-width: 1688px) calc(((100vw - 288px - 54px) / 4)),
      337px
      `
    } else if (size === `medium`) {
      return `
      (max-width: 500px) calc((100vw - 36px - 18px) / 2),
      (max-width: 1000px) calc(((100vw - 144px - 18px) / 2)),
      (max-width: 1688px) calc(((100vw - 288px - 36px) / 3)),
      455px
      `
    } else {
      return `
      (max-width: 500px) calc((100vw - 36px)),
      (max-width: 738px) calc(((100vw - 144px))),
      (max-width: 1000px) calc(((100vw - 144px - 18px) / 2)),
      (max-width: 1688px) calc(((100vw - 288px - 18px) / 2)),
      691px
      `
    }
  }

  return (
    <figure className="slice">
      <div className={`imagegrid ${size}`}>
        {items.map(({ image }) => (
          <img
            alt={caption ? caption : ''}
            src={image.thumbnails.small.url}
            sizes={getSizes(size)}
            srcSet={`
              ${image.thumbnails.small.url} 500w,
              ${image.thumbnails.medium.url} 1000w,
              ${image.thumbnails.large.url} 1600w,
              ${image.url} 2800w
            `}
          />
        ))}
      </div>
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  )
}

const Project = ({ data, location }) => {
  const project = data.prismicWork.data
  return (
    <Layout>
      <Meta
        data={{
          pathName: location.pathname,
          title: project.title.text,
          description: project.description,
          image: project.main_image.thumbnails.medium.url,
        }}
      />
      <article>
        <div className="container container-compact projectintro">
          <div>
            <span>Studio WillemsPeeters for {project.client}</span>
            <h1>{project.title.text}</h1>
          </div>
        </div>
        {project.main_image.url ? (
          <img
            className="main-image"
            alt=""
            src={project.main_image.thumbnails.small.url}
            srcSet={`
            ${project.main_image.thumbnails.small.url} 500w,
            ${project.main_image.thumbnails.medium.url} 1000w,
            ${project.main_image.thumbnails.large.url} 2000w,
            ${project.main_image.url} 4000w
            `}
          />
        ) : null}
        <div className="container container-compact">
          {project.body
            ? project.body.map(item => (
                <Slice
                  type={item.slice_type}
                  primary={item.primary}
                  items={item.items}
                  key={item.id}
                />
              ))
            : null}
          <Calltoaction />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    prismicWork(uid: { eq: $slug }) {
      data {
        client
        description
        title {
          text
        }
        main_image {
          url
          thumbnails {
            large {
              url
            }
            medium {
              url
            }
            small {
              url
            }
            lqip {
              url
            }
          }
        }
        body {
          ... on PrismicWorkDataBodyAfbeeldingengrid {
            id
            slice_type
            primary {
              size
              caption
            }
            items {
              image {
                url
                thumbnails {
                  large {
                    url
                  }
                  medium {
                    url
                  }
                  small {
                    url
                  }
                  lqip {
                    url
                  }
                }
              }
            }
          }
          ... on PrismicWorkDataBodyTekst {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicWorkDataBodyVimeo {
            id
            slice_type
            primary {
              vimeo_url
            }
          }
          ... on PrismicWorkDataBodyAfbeelding {
            id
            slice_type
            primary {
              image {
                url
                thumbnails {
                  large {
                    url
                  }
                  medium {
                    url
                  }
                  small {
                    url
                  }
                  lqip {
                    url
                  }
                }
              }
              alt_text
              caption
              alignment
            }
          }
        }
      }
    }
  }
`

export default Project
